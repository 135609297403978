<template>
  <div class="welcome-container">
    <video autoplay muted loop id="welcome-background-video" poster="/images/bg/transparent.png">
      <source
        src="../assets/videos/welcome/welcome-bg-video.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
    <div class="content">
      <h3
        class="welcome-text"
        v-animate-letters="{
          animationName: 'letterFadeIn',
          delayForNextLetter: 0.14,
          durationOfEachLetter: 0.45,
        }"
      >
        HOŞGELDİN
      </h3>
      <h1
        v-if="isDescriptionShow"
        class="client-description"
        v-animate-letters="{
          animationName: 'letterFadeIn',
          delayForNextLetter: 0.14,
          durationOfEachLetter: 0.45,
        }"
      >
        {{ welcomeData.description.toUpperCase() }}
      </h1>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "welcome",
  props: ["data"],
  data() {
    return {
      fileUrl: "/images/bg/welcome.png",
      isDescriptionShow: false,
    };
  },
  computed: {
    ...mapGetters("welcome", ["welcomeData"]),
    ...mapGetters(["logo"]),
  },
  methods: {
    ...mapActions(["setNextPlaylist"]),
    startTimer() {
      if (this.welcomeData.image) {
        this.fileUrl =
          process.env.VUE_APP_BASE_CDN_URL +
          "/" +
          this.welcomeData.image.fileName;
      } else {
        this.fileUrl = process.env.VUE_APP_BASE_CDN_URL + "/" + this.logo;
      }
      setTimeout(() => {
        this.setNextPlaylist();
      }, (this.welcomeData.displaySecond || 30) * 1000);
    },
    showDescription() {
      setTimeout(() => {
        this.isDescriptionShow = true;
      }, 2000);
    },
  },
  created() {
    this.startTimer();
    this.showDescription();
  },
};
</script>

<style lang="scss">
.welcome-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  #welcome-background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .content {
    text-align: center;
    white-space: nowrap;
    .welcome-text {
      position: absolute;
      left: 49%;
      top: 32%;
      transform: translate(-50%, -50%);
      font-size: 18vw;
      font-family: ZingRustBase;
      color: #fffefd;
      text-shadow: 13px 13px 2px #6310106e;
    }
    .client-description {
      position: absolute;
      left: 50%;
      top: 70%;
      transform: translate(-50%, -50%);
      font-size: 18vw;
      font-family: ZingRustBase;
      color: #fffefd;
      text-shadow: 13px 13px 2px #00295877;
      padding: 2px 60px;
      border-radius: 40px;
    }
  }
}
@keyframes letterFadeIn {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
</style>
