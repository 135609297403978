var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"welcome-container"},[_c('video',{attrs:{"autoplay":"","muted":"","loop":"","id":"welcome-background-video","poster":"/images/bg/transparent.png"},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../assets/videos/welcome/welcome-bg-video.mp4"),"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")]),_c('div',{staticClass:"content"},[_c('h3',{directives:[{name:"animate-letters",rawName:"v-animate-letters",value:({
        animationName: 'letterFadeIn',
        delayForNextLetter: 0.14,
        durationOfEachLetter: 0.45,
      }),expression:"{\n        animationName: 'letterFadeIn',\n        delayForNextLetter: 0.14,\n        durationOfEachLetter: 0.45,\n      }"}],staticClass:"welcome-text"},[_vm._v(" HOŞGELDİN ")]),(_vm.isDescriptionShow)?_c('h1',{directives:[{name:"animate-letters",rawName:"v-animate-letters",value:({
        animationName: 'letterFadeIn',
        delayForNextLetter: 0.14,
        durationOfEachLetter: 0.45,
      }),expression:"{\n        animationName: 'letterFadeIn',\n        delayForNextLetter: 0.14,\n        durationOfEachLetter: 0.45,\n      }"}],staticClass:"client-description"},[_vm._v(" "+_vm._s(_vm.welcomeData.description.toUpperCase())+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }